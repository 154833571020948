import React, { useState } from 'react';
import { IGroupRequestProps } from 'Groups/Widget/PendingGroupList/IGroupRequestProps';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Button } from 'Groups/Widget/Button/Button';
import {
  DELETE_GROUP_REQUEST_MODAL,
  VIEW_GROUP_REQUEST,
  WITHDRAW_GROUP_REQUEST,
} from 'Groups/Widget/PendingGroupList/dataHooks';
import { ButtonPriority } from 'wix-ui-tpa';
import { classes } from 'Groups/Widget/PendingGroupList/PendingGroupActions.st.css';
import { Modal } from 'common/components/Modal';
import { DeleteGroupRequestModal } from 'Groups/Widget/modals/DeleteGroupRequestModal/DeleteGroupRequestModal';

type Props = Omit<
  IGroupRequestProps,
  'canResolvePendingGroups' | 'createdBy' | 'goToGroup'
>;
export const CreatorActions: React.FC<Props> = ({
  groupUrl,
  groupRequest,
  groupRequestsActions,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const handleConfirmGroupRequestDeletion = () => {
    groupRequestsActions.cancelGroupRequest({
      groupRequestId: groupRequest.id ?? undefined,
    });
    closeModal();
  };

  return (
    <>
      <div className={classes.root}>
        <Button
          data-hook={VIEW_GROUP_REQUEST}
          priority={ButtonPriority.secondary}
          fullWidth={true}
          className={classes.actionButton}
          as="a"
          href={groupUrl}
        >
          {t('groups-web.btn.view-group')}
        </Button>
        <Button
          data-hook={WITHDRAW_GROUP_REQUEST}
          priority={ButtonPriority.primary}
          onClick={openModal}
          fullWidth={true}
          className={classes.actionButton}
        >
          {t('groups-web.group-list.pending-groups.button.delete')}
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        dataHook={DELETE_GROUP_REQUEST_MODAL}
      >
        <DeleteGroupRequestModal
          onConfirmGroupDeletion={handleConfirmGroupRequestDeletion}
          onRequestClose={closeModal}
        />
      </Modal>
    </>
  );
};

CreatorActions.displayName = 'CreatorActions';
