import React, { useState } from 'react';
import { Button } from 'Groups/Widget/Button/Button';
import {
  APPROVE_GROUP_REQUEST,
  DECLINE_GROUP_REQUEST_MODAL,
  REJECT_GROUP_REQUEST,
} from 'Groups/Widget/PendingGroupList/dataHooks';
import { ButtonPriority } from 'wix-ui-tpa';
import {
  st,
  classes,
} from 'Groups/Widget/PendingGroupList/PendingGroupActions.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { IGroupRequestProps } from 'Groups/Widget/PendingGroupList/IGroupRequestProps';
import { DeclineGroupRequestModal } from 'Groups/Widget/modals/DeclineGroupRequestModal/DeclineGroupRequestModal';
import { Modal } from 'common/components/Modal';

type Props = Omit<
  IGroupRequestProps,
  'canResolvePendingGroups' | 'goToGroup' | 'groupUrl'
>;
export const OwnerActions: React.FC<Props> = ({
  createdBy,
  groupRequestsActions,
  groupRequest,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const authorName = createdBy.getName(t('groups-web.member.anonymous'));

  const onApproveClick = () => {
    try {
      groupRequestsActions.approveGroupRequests({
        groupRequestIds: [groupRequest?.id!],
      });
    } catch (e) {}
  };

  const handleConfirmGroupRejection = (reason: string) => {
    try {
      groupRequestsActions.rejectGroupRequests({
        rejections: [{ groupRequestId: groupRequest?.id!, reason }],
      });
    } catch (e) {
      closeModal();
    }
  };

  return (
    <>
      <div className={st(classes.root)}>
        <Button
          data-hook={REJECT_GROUP_REQUEST}
          priority={ButtonPriority.secondary}
          onClick={openModal}
          fullWidth={true}
          className={classes.actionButton}
        >
          {t('groups-web.group-list.pending-groups.button.decline')}
        </Button>
        <Button
          data-hook={APPROVE_GROUP_REQUEST}
          priority={ButtonPriority.primary}
          onClick={onApproveClick}
          fullWidth={true}
          className={classes.actionButton}
        >
          {t('groups-web.group-list.pending-groups.button.approve')}
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        dataHook={DECLINE_GROUP_REQUEST_MODAL}
      >
        <DeclineGroupRequestModal
          onConfirmGroupRejection={handleConfirmGroupRejection}
          onRequestClose={closeModal}
          creatorName={authorName}
        />
      </Modal>
    </>
  );
};

OwnerActions.displayName = 'OwnerActions';
